import React, { Component } from "react";
class Loading extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <div id="loading" className="overlay ">
          <div className="overlay-content">
            <div class="spinner-border text-bit" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div className="text-white text-pixel mt-5 blink fs-4 text-bit">
              {this.props.text}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Loading;
