import React, { Component } from "react";

class Navbar extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  handlePage = () => {
    if (this.props.page === "mint") {
      this.props.setPage("history");
    } else {
      this.props.setPage("mint");
    }
  };
  handleUser = (user) => {
    console.log("user from navbar: ", user);
    this.props.setUser(user);
  };
  render() {
    return (
      <>
        <nav className="navbar fixed-top border-up mb-2 ">
          <div className="container-fluid justify-content-end align-items-center">
            {/* <span className="me-auto"> </span> */}

            <span className="navbar-brand">
              <span className=" c-pointer ord-btn">
                <a
                  className="not-underline text-white"
                  href="https://ordgoose.com"
                >
                  HOME
                </a>
              </span>
            </span>

            <span className="me-auto"> </span>
          </div>
        </nav>
      </>
    );
  }
}

export default Navbar;
