import React, { Component } from "react";
class Footer extends Component {
  constructor(props) {
    super(props);
  }
  state = {};
  render() {
    return (
      <>
        <nav className="navbar fixed-bottom border-up footer">
          <div className="container-fluid justify-content-end align-items-center">
            <span className="me-auto"> </span>

            <a
              rel="noopener noreferrer"
              className="navbar-brand text-white"
              href="https://x.com/OrdGoose"
              target="_blank"
            >
              <span class="fs-3">
                <i class="bi bi-twitter-x text-white"></i>
              </span>
            </a>
            <a
              rel="noopener noreferrer"
              className="navbar-brand text-white"
              href="https://discord.gg/OrdGoose"
              target="_blank"
            >
              <span class="fs-2">
                <i class="bi bi-discord"></i>
              </span>
            </a>
          </div>
        </nav>
      </>
    );
  }
}

export default Footer;
