import React, { useState, useEffect } from "react";
import Loading from "./Loading";

const Main = () => {
  // console.log("user has changed:", user);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [response, setResponse] = useState("");
  const [wallet, setWallet] = useState();
  const handleTWallet = (e) => {
    setWallet(e.target.value);
  };
  const handleCheckWallet = async () => {
    setError("");
    setResponse("");
    setLoading(true);
    const res = await checkWL(wallet);

    if (res !== null) {
      setResponse(res.spot);

      setLoading(false);
    } else {
      setError("Something wrong, try again.");
      setLoading(false);
    }
  };

  const checkWL = async (w) => {
    try {
      const response = await fetch(`https://api.ordgoose.com/checkEligible`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          wallet: w,
        }),
      });

      if (!response.ok) {
        return null;
      }

      const data = await response.json();

      return data;
    } catch (error) {
      return null;
    }
  };
  if (loading) {
    return (
      <>
        <Loading text={"WAIT..."} />
      </>
    );
  } else {
    return (
      <>
        <div class="wrapper work d-flex  h-100">
          <div class="con m-auto d-flex flex-column align-items-center border-cus  ">
            <div class="w-cus  justify-content-center p-2">
              <div class="row  justify-content-center p-2">
                <div class=" col-12 fs-6 fw-bold text-white text-pixel  row justify-content-center">
                  {" "}
                  <img className="image" src="./images/ordgoose.png" />
                </div>
                <div class=" col-12 fs-6 fw-bold text-white text-pixel  row">
                  <label for="exampleFormControlInput1" class="form-label fs-8">
                    Wallet address
                  </label>
                  <div class="input-group mb-3">
                    <input
                      id="taproot-wallet"
                      type="text"
                      class="form-control fs-8"
                      placeholder={wallet}
                      onChange={(e) => {
                        handleTWallet(e);
                      }}
                    />
                    <button
                      class="btn  ord-btn fs-8"
                      type="button"
                      id="button-addon2"
                      onClick={() => {
                        handleCheckWallet();
                      }}
                    >
                      <i class="bi bi-search fs-6"></i>
                    </button>
                  </div>
                </div>
                {error === "" ? (
                  <></>
                ) : (
                  <>
                    <div className="row p-1 ms-1 mt-2   fs-6 fw-bold border-cus bg-danger position-relative justify-content-center ">
                      <span className="w-100 text-end position-absolute">
                        <i
                          class="bi bi-eye-slash text-white c-pointer fs-5 "
                          onClick={() => {
                            setError("");
                          }}
                        ></i>
                      </span>
                      <div className="col-10 text-center"> {error}</div>
                    </div>
                  </>
                )}
                {response === "" ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    {response === "wl" ? (
                      <>
                        <div className="col-12 fs-4 fw-bolder text-warning text-pixel  row border-cus bg-success  justify-content-center ">
                          <div className=" text-center blink">
                            {" "}
                            You are eligible (WL), Honk Honk...{" "}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {response === "og" ? (
                      <>
                        <div className="col-12 fs-4 fw-bolder text-bit text-pixel  row border-cus bg-success  justify-content-center ">
                          <div className=" text-center blink">
                            {" "}
                            You are eligible (OG), Honk Honk...
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {response === "public" ? (
                      <>
                        <div className="col-12 fs-4 fw-bolder text-white text-pixel  row border-cus bg-dark  justify-content-center flex-column ">
                          <div className=" text-center ">
                            {" "}
                            You are not eligible, still have a chance{" "}
                            <i class="bi bi-arrow-down fs-4 text-bit blink"></i>
                          </div>
                          <a
                            rel="noopener noreferrer"
                            className="navbar-brand text-white w-100 text-center"
                            href="https://discord.gg/OrdGoose"
                            target="_blank"
                          >
                            <span class="fs-2">
                              <i class="bi bi-discord"></i>
                            </span>
                          </a>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Main;
